/* 모바일 전용 */
@media screen and (max-width: 767px) {
    .tb-only,
    .lt-only,
    .dt-only {
        display: none;
    }
}

/* 태블릿 전용 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .mb-only,
    .lt-only,
    .dt-only {
        display: none;
    }
}

/* 랩탑 전용 */
@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .mb-only,
    .tb-only,
    .dt-only {
        display: none;
    }
}

/* 데스크탑 전용 */
@media screen and (min-width: 1441px) {
    .mb-only,
    .tb-only,
    .lt-only {
        display: none;
    }
}